/* margin */

.margin-left-minus-53 {
  margin-left: -53px !important;
}

.margin-left-minus-44 {
  margin-left: -44px !important;
}

.margin-left-minus-35 {
  margin-left: -35px !important;
}

.margin-top-minus-20 {
  margin-top: -20px !important;
}

.margin-left-minus-17 {
  margin-left: -17px !important;
}

.margin-left-minus-16 {
  margin-left: -16px !important;
}

.margin-top-minus-16 {
  margin-top: -16px !important;
}

.margin-left-minus-15 {
  margin-left: -15px !important;
}

.margin-top-minus-12 {
  margin-top: -12px !important;
}

.margin-top-minus-10 {
  margin-top: -10px !important;
}

.margin-top-minus-8 {
  margin-top: -8px !important;
}

.margin-left-minus-7 {
  margin-left: -7px !important;
}

.margin-top-minus-5 {
  margin-top: -5px !important;
}

.margin-top-0 {
  margin-top: 0 !important;
}

.margin-left-0 {
  margin-left: 0 !important;
}

.margin-vertical-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.margin-top-2 {
  margin-top: 2px !important;
}

.margin-left-3 {
  margin-left: 3px !important;
}

.margin-left-5 {
  margin-left: 5px !important;
}

.margin-right-5 {
  margin-right: 5px !important;
}

.margin-top-5 {
  margin-top: 5px !important;
}

.margin-left-7 {
  margin-left: 7px !important;
}

.margin-bottom-7 {
  margin-bottom: 7px !important;
}

.margin-left-8 {
  margin-left: 8px !important;
}

.margin-top-8 {
  margin-top: 8px !important;
}

.margin-bottom-8 {
  margin-bottom: 8px !important;
}

.margin-top-10 {
  margin-top: 10px !important;
}

.margin-bottom-10 {
  margin-bottom: 10px !important;
}

.margin-left-10 {
  margin-left: 10px !important;
}

.margin-right-10 {
  margin-right: 10px !important;
}

.margin-vertical-10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.margin-top-12 {
  margin-top: 12px !important;
}

.margin-left-12 {
  margin-left: 12px !important;
}

.margin-right-12 {
  margin-right: 12px !important;
}

.margin-top-13 {
  margin-top: 13px !important;
}

.margin-left-14 {
  margin-left: 14px !important;
}

.margin-top-20 {
  margin-top: 20px !important;
}

.margin-bottom-20 {
  margin-bottom: 20px !important;
}

.margin-right-20 {
  margin-right: 20px !important;
}

.margin-top-25 {
  margin-top: 25px !important;
}

.margin-left-25 {
  margin-left: 25px !important;
}

.margin-top-30 {
  margin-top: 30px !important;
}

.margin-left-30 {
  margin-left: 30px !important;
}

.margin-top-38 {
  margin-top: 38px !important;
}

.margin-top-50 {
  margin-top: 50px !important;
}

/* margin */

/* padding */

.padding-left-0 {
  padding-left: 0 !important;
}

.padding-right-0 {
  padding-right: 0 !important;
}

.padding-top-0 {
  padding-top: 0 !important;
}

.padding-vertical-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.padding-bottom-0 {
  padding-bottom: 0 !important;
}

.padding-top-1 {
  padding-top: 1px !important;
}

.padding-top-2 {
  padding-top: 2px !important;
}

.padding-top-4 {
  padding-top: 4px !important;
}

.padding-top-5 {
  padding-top: 5px !important;
}

.padding-bottom-5 {
  padding-bottom: 5px !important;
}

.padding-left-5 {
  padding-left: 5px !important;
}

.padding-5 {
  padding: 5px !important;
}

.padding-top-6 {
  padding-top: 6px !important;
}

.padding-top-8 {
  padding-top: 8px !important;
}

.padding-left-10 {
  padding-left: 10px !important;
}

.padding-top-10 {
  padding-top: 10px !important;
}

.padding-bottom-10 {
  padding-bottom: 10px !important;
}

.padding-vertical-10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.padding-left-12 {
  padding-left: 12px !important;
}

.padding-right-20 {
  padding-right: 20px !important;
}

.padding-bottom-20 {
  padding-bottom: 20px !important;
}

/* padding */
