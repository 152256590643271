/* height */

.height-5-pct {
  height: 5% !important;
}

.height-10-pct {
  height: 10% !important;
}

.height-20-pct {
  height: 20% !important;
}

.height-37 {
  height: 37px !important;
}

.height-40 {
  height: 40px !important;
}

.height-45 {
  height: 45px !important;
}

.height-48 {
  height: 48px !important;
}

.min-height-70 {
  min-height: 70px !important;
}

.height-93 {
  height: 93px !important;
}

.height-100-pct {
  height: 100% !important;
}

.height-200 {
  height: 200px !important;
}

/* height */

/* width */

.width-35-pct {
  width: 35% !important;
}

.width-40 {
  width: 40px !important;
}

.max-width-40-5 {
  max-width: 40.5px !important;
}

.min-width-50 {
  min-width: 50px !important;
}

.width-56 {
  width: 56px !important;
}

.min-width-60 {
  min-width: 60px !important;
}

.width-66 {
  width: 66px !important;
}

.width-69 {
  width: 69px !important;
}

.width-90 {
  width: 90px !important;
}

.width-100-pct {
  width: 100% !important;
}

.width-120 {
  width: 120px !important;
}

.width-150 {
  width: 150px !important;
}

.width-153 {
  width: 153px !important;
}

.width-210 {
  width: 210px !important;
}

.width-270 {
  width: 270px !important;
}

.width-300 {
  width: 300px !important;
}

.min-width-320 {
  min-width: 320px !important;
}

.width-384 {
  width: 384px !important;
}

.large-screen-content-fixed-width-1000 {
  width: 1000px !important;
}

.large-screen-content-fixed-width-550 {
  width: 550px !important;
}
/* width */
