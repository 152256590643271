/* Others */

.logo {
  width: 200px !important;
  height: 20px !important;
  margin-top: 5px !important;
}

.text-transform-none {
  text-transform: none !important;
}

.display-none {
  display: none !important;
}

.display-block {
  display: block !important;
}

.user-name {
  text-transform: capitalize !important;
  display: block !important;
}

.sign-out,
.log-in {
  border-bottom: solid !important;
  border-bottom-color: white !important;
  padding-bottom: 15px !important;
}

.error-message {
  min-height: 2.2rem !important;

  ion-label {
    margin: 2px 0 !important;
    font-size: 14px !important;
    color: #ff6153 !important;
  }

  .item-inner {
    border-bottom: 0 !important;
  }
}

.remove-outline {
  outline: none !important;
}

.info-help {
  display: flex !important;
  margin: 16px auto 32px !important;
  padding: 0 16px !important;
  max-width: 960px !important;
  flex-direction: column !important;
  align-items: left !important;
}

.box-shadow-none {
  box-shadow: none !important;
}

.text-align-center {
  text-align: center !important;
}

.drawer-style {
  border-top-left-radius: 25px !important;
  border-top-right-radius: 25px !important;
  border-top-color: #29546a !important;
  border-top-style: solid !important;
  border-left-width: 2px !important;
}

.border-radius-8 {
  border-radius: 8px !important;
}

.border-radius-10 {
  border-radius: 10px !important;
}

.border-radius-20 {
  border-radius: 20px !important;
}

.rounded-button {
  background-color: var(--light-theme-color) !important;
  border: none !important;
  padding: 10px !important;
  text-align: center !important;
  display: inline-block !important;
  border-radius: 12px !important;
  width: 300px !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.ion-input-background-color {
  background-color: #042a3e !important;
  border-radius: 10px !important;
  padding-left: 5px !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.level {
  display: table-cell;
  height: 20px;
  width: 20px;
  text-align: center;
  vertical-align: middle;
  border-radius: 50%;
}

.subscription-submit-button {
  width: 150px !important;
  height: 48px !important;
}

.opacity-1 {
  opacity: 1 !important;
}

/* Others */

/* Tooltip component */

.popover-size-members-tooltip {
  --width: 60% !important;
}

.popover-size-queue-board-tooltip {
  --width: 70% !important;
}

.popover-size-tooltip {
  --width: 45% !important;
}

.popover-size-admin-menu-tooltip {
  --width: 35% !important;
}

.popover-size-leave-group-tooltip {
  --width: 28% !important;
}

/* Tooltip component */

/* Ionic components styles */

ion-content {
  --ion-background-color: var(--dark-theme-color) !important;
}

ion-picker > .picker-wrapper,
ion-alert > .alert-wrapper {
  background-color: var(--dark-theme-color) !important;
}

.picker-opt {
  color: var(--light-blue) !important;
}

.picker-opt.picker-opt-selected,
ion-datetime,
.alert-checkbox-label,
.alert-title,
ion-select {
  color: var(--ion-color-light) !important;
}

.picker-button,
.alert-button {
  text-transform: none !important;
}

.picker-button {
  color: var(--light-white-color) !important;
}

.searchbar-input {
  color: white !important;
}

ion-searchbar {
  --icon-color: white !important;
}

ion-popover {
  --background: var(--light-gray) !important;
  --color: white !important;
  --backdrop-opacity: 0.6 !important;
}

ion-popover ion-content {
  --background: var(--light-gray) !important;
}

.ion-accordion-toggle-icon {
  color: white !important;
}

.payments > .ion-accordion-toggle-icon {
  margin-right: 16px !important;
  margin-left: -10px !important;
}

ion-item[slot='header'] {
  background: #042a3e !important;
  color: white !important;
}

ion-toast.cookie-toast::part(button) {
  background-color: var(--orange) !important;
  border: none !important;
  color: white !important;
  text-transform: none !important;
  border-radius: 64px !important;
}

/* play-next alert box */

.play-next .alert-message {
  color: white !important;
  font-size: 15px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 160% !important;
  letter-spacing: 0.036px !important;
  margin-top: 16px !important;
  text-align: start !important;
}

.play-next .alert-title {
  color: white !important;
  text-align: start !important;
  font-size: 22px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 120% !important;
  letter-spacing: 0.077px !important;
  margin-bottom: 16px !important;
}

.play-next .alert-head {
  background-color: #f0810f !important;
}

.play-next .alert-wrapper {
  background: #708d9c !important;
}

.play-next .alert-button-inner {
  color: white !important;
  font-size: 15px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 160% !important;
  letter-spacing: 0.036px !important;
}

.play-next {
  --min-width: 325px !important;
}

.play-next .alert-button.ion-focused.sc-ion-alert-ios,
.alert-tappable.ion-focused.sc-ion-alert-ios {
  background: #708d9c !important;
}

/* play-next alert box */

ion-modal#attendee-level-modal {
  --width: 240px;
  --min-width: 240px;
  --height: 223px;
  --border-radius: 6px;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
  --background: var(--dark-theme-color);
}

ion-modal#attendee-move-to-modal {
  --width: 240px;
  --min-width: 240px;
  --height: 155px;
  --border-radius: 6px;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
  --background: var(--dark-theme-color);
}

.alert-radio-label {
  color: white !important;
}

.level-select-alert .alert-wrapper {
  min-width: 160px !important;
}

.searchbar-input {
  padding-right: 10px !important;
}

.venue > .searchbar-input-container > .searchbar-input {
  padding-right: 0 !important;
  padding-left: 50px !important;
}

ion-datetime {
  --background: rgb(4, 42, 62) !important;
  --background-rgb: 4, 42, 62 !important;
}

.venue {
  input {
    font-size: 14px !important;
  }
}

.event-cancel-alert .alert-message {
  font-size: 15px !important;
  color: white !important;
}

.event-cancel-alert .alert-button {
  border-top-color: gray !important;
  border-right-color: gray !important;
}

.event-cancel-alert .alert-head {
  border-bottom-width: 1px !important;
  border-bottom-style: solid !important;
  border-color: gray !important;
}

.event-cancel-alert .alert-message {
  padding-top: 16px !important;
}
/* Ionic components styles */

/* PrimeNG Styles */

.p-accordion-header {
  font-size: 20px !important;
  font-weight: 700 !important;
  margin-bottom: 16px !important;
}

.p-panelmenu .p-menuitem-text {
  font-size: 20px !important;
  color: white !important;
  margin-left: 30px !important;
}

.p-panelmenu-panel {
  margin-top: 30px !important;
}

.p-toggleable-content {
  border-bottom: solid !important;
  border-bottom-color: white !important;
  padding-bottom: 15px !important;
}

.p-menuitem-icon {
  margin-left: 10px !important;
}

.p-menuitem-link > .p-menuitem-text {
  margin-left: 70px !important;
  margin-top: 20px !important;
}

.p-menuitem-icon,
.p-panelmenu-icon {
  font-size: 20px !important;
}

.p-toggleable-content .ng-trigger-submenu {
  border-bottom: none !important;
  padding-bottom: 0 !important;
}

.p-menubar .p-menuitem-text {
  width: 80px !important;
  color: white !important;
}

.p-menubar .p-menubar-root-list .p-icon-wrapper,
.p-menubar .p-submenu-list .p-menuitem-link .p-icon-wrapper {
  margin-top: 16px !important;
  color: white !important;
}

li.desktop-top-menu > .p-menuitem-content > .p-menuitem-link > .p-menuitem-text {
  margin-top: 8px !important;
  font-size: 14px !important;
}

/* PrimeNG Styles */

/* swiper */

swiper-slide {
  display: flex;
  position: relative;
  flex-direction: column;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-size: 18px;
  text-align: center;
  box-sizing: border-box;
}

swiper-container {
  --swiper-pagination-color: var(--ion-color-primary);
}

/* swiper */

/* Media queries */

@media only screen and (min-width: 992px) {
  .split-pane {
    --side-max-width: 10% !important;
  }
}

/* Media queries */
