/* color */

.color-black {
  color: black !important;
}

.color-red {
  color: red !important;
}

.color-white {
  color: white !important;
}

.color-light-white {
  color: var(--light-white-color) !important;
}

.color-primary {
  color: var(--ion-color-primary) !important;
}

.color-orange {
  color: var(--orange) !important;
}

.color-gray {
  color: #b2b2b2 !important;
}

.color-dark-blue {
  color: #94a9b4 !important;
}

/* color */

/* background-color */

.background-color-primary {
  background-color: var(--ion-color-primary) !important;
}

.background-color-medium {
  background-color: var(--ion-color-medium) !important;
}

.background-color-green {
  background-color: #b2ca9c !important;
}

.background-color-brown {
  background-color: #ecc3b2 !important;
}

.background-dark-theme-color {
  background-color: var(--dark-theme-color) !important;
}

.background-light-theme-color {
  background-color: var(--light-theme-color) !important;
}

.background-light-gray-color {
  background-color: var(--light-gray-color) !important;
}

.background-darker-theme-color {
  background-color: var(--darker-theme-color) !important;
}

.background-color-dark-gray {
  background-color: var(--dark-gray); //don't put !important here
}

.background-color-light-gray {
  background-color: var(--light-gray) !important;
}

.background-color-dark-brown {
  background-color: var(--dark-brown) !important;
}

.background-color-light-blue {
  background-color: #b1d8d8 !important;
}

.background-color-light-orange {
  background-color: #fffaf0 !important;
}

.background-color-white {
  background-color: white !important;
}

.attendees-level-background-color {
  background-color: #476c7f !important;
}

/* background-color */
