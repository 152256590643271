@font-face {
    font-family: "Inter-Regular";
    src: url("../assets/fonts/Inter-Regular.ttf");
}

@font-face {
    font-family: "Inter-Bold";
    src: url("../assets/fonts/Inter-Bold.ttf");
}

@font-face {
    font-family: "Inter-Medium";
    src: url("../assets/fonts/Inter-Medium.ttf");
}

* {
    font-family: "Inter-Regular";
}

.font-bold {
    font-family: "Inter-Bold" !important;
}

.font-medium {
    font-family: "Inter-Medium" !important;
}

/* font-size */

.font-size-11 {
    font-size: 11px !important;
}

.font-size-12 {
    font-size: 12px !important;
}

.font-size-13 {
    font-size: 13px !important;
}

.font-size-14 {
    font-size: 14px !important;
}


.font-size-15 {
    font-size: 15px !important;
}

.font-size-16 {
    font-size: 16px !important;
}

.font-size-17 {
    font-size: 17px !important;
}

.font-size-18 {
    font-size: 18px !important;
}

.font-size-20 {
    font-size: 20px !important;
}

.font-size-22 {
    font-size: 22px !important;
}

.font-size-25 {
    font-size: 25px !important;
}

.font-size-30 {
    font-size: 30px !important;
}

.font-size-40 {
    font-size: 40px !important;
}

.font-size-60 {
    font-size: 60px !important;
}

.font-size-64 {
    font-size: 64px !important;
}

/* font-size */

/* font-weight */

.font-weight-normal {
    font-weight: normal !important;
}

.font-weight-400 {
    font-weight: 400 !important;
}

.font-weight-500 {
    font-weight: 500 !important;
}

.font-weight-600 {
    font-weight: 600 !important;
}

.font-weight-700 {
    font-weight: 700 !important;
}

/* font-weight */